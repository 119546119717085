<template>
  <div>
    <table-component
      ref="tableComponent"
      :model="model"
      :show-form="false"
      url-create-form="/create-product"
      url-edit-form="/edit-product"
      force-paginate
      @clearFilter="clearFilter"
    >
      <template v-slot:actions>
        <b-button
          :variant="onlySellerProducts ? 'primary' : 'outline-primary'"
          @click="showSellersProducts($event)"
        >
          Ver solo de vendedores
        </b-button>

        <b-button
          :variant="onlyPendingProducts && status===1 ? 'primary' : 'outline-primary'"
          @click="showPendingProducts()"
        >
          Solo pendientes
        </b-button>

        <b-button
          :variant="onlyPendingProducts && status===2 ? 'primary' : 'outline-primary'"
          @click="showPendingProducts(2)"
        >
          Solo activos
        </b-button>
      </template>
    </table-component>
  </div>
</template>

<script>
import TableComponent from '@/dc-it/components/TableComponent.vue'
import Product from '@/dc-it/models/Product'
import { BButton } from 'bootstrap-vue'

export default {
  name: 'ProductView',
  components: { TableComponent, BButton },
  data() {
    return {
      model: Product,
      onlySellerProducts: false,
      onlyPendingProducts: false,
      status: 1,
    }
  },
  methods: {
    clearFilter() {
      if (this.onlyPendingProducts) {
        this.showPendingProducts()
      } else if (this.onlySellerProducts) {
        this.showSellersProducts()
      }
    },
    showPendingProducts(status = 1) {
      if (this.onlyPendingProducts && this.status === status) {
        this.onlyPendingProducts = false
        this.$refs.tableComponent.filterFields = []
        this.$refs.tableComponent.clearFilter()
      } else {
        this.status = status
        this.$refs.tableComponent.filterFields = [
          {
            field: 'status',
            value: status,
          },
        ]
        this.$refs.tableComponent.applyFilter()
        this.onlyPendingProducts = true
        this.onlySellerProducts = false
      }
    },
    showSellersProducts() {
      if (this.onlySellerProducts) {
        this.onlySellerProducts = false
        this.$refs.tableComponent.filterFields = []
        this.$refs.tableComponent.clearFilter()
      } else {
        this.$refs.tableComponent.filterFields = [
          {
            field: 'internal_product',
            value: 0,
          },
        ]
        this.$refs.tableComponent.applyFilter()
        this.onlySellerProducts = true
        this.onlyPendingProducts = false
      }
    },
  },
}
</script>
